// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required
@import "mixins.scss";

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";


//
// Custom styles
//

@import "icon-list";


.full-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom,
  #{fade-out($black, 0.9)} 0%,
  #{fade-out($black, 0.7)} 55%,
  $black 100%), url('../img/bg-pic.jpg');
  background-size: cover;
  background-position: center;
}

.logo-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: 20vh;
}

.card {
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

body {
  padding: 0;
}

// Style Bootstrap icons
.bi {
  fill: currentcolor;
}

// @media(min-width:480px) {
//   .logo-img {
//     height: 10vh;
//   }
// }

// @media(min-width:768px) {
//   .logo-img {
//     height: 30vh;
//   }
// }

// Styling for the portfolio section
.products {
  .portfolio-item {
    right: 0;
    margin: 0 0 15px;
    .portfolio-link {
      position: relative;
      display: block;
      max-width: 400px;
      margin: 0 auto;
      cursor: pointer;
      .portfolio-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        transition: all ease 0.5s;
        opacity: 0;
        background: fade-out($primary, .1);
        &:hover {
          opacity: 1;
        }
        .portfolio-hover-content {
          font-size: 20px;
          position: absolute;
          top: 50%;
          width: 100%;
          height: 20px;
          margin-top: -12px;
          text-align: center;
          color: white;
          i {
            margin-top: -12px;
          }
          h3,
          h4 {
            margin: 0;
          }
        }
      }
    }
    .portfolio-caption {
      max-width: 400px;
      margin: 0 auto;
      padding: 25px;
      text-align: center;
      background-color: $white;
      h4 {
        margin: 0;
        text-transform: none;
      }
      p {
        font-size: 16px;
        font-style: italic;
        margin: 0;
        @include serif-font;
      }
      .product-wrap {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  * {
    z-index: 2;
  }
}
@media(min-width:767px) {
  .products {
    .portfolio-item {
      margin: 0 0 30px;
    }
  }
}

.portfolio-modal {
  .modal-dialog {
    margin: 1rem;
    max-width: 100vw;
  }
  .modal-content {
    padding: 100px 0;
    text-align: center;
    h2 {
      font-size: 3em;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 30px;
    }
    p.item-intro {
      font-size: 16px;
      font-style: italic;
      margin: 20px 0 30px;
      @include serif-font;
    }
    ul.list-inline {
      margin-top: 0;
      margin-bottom: 30px;
    }
    img {
      margin-bottom: 30px;
    }
    button {
      cursor: pointer;
    }
  }
  .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    cursor: pointer;
    background-color: transparent;
    &:hover {
      opacity: 0.3;
    }
    .lr {
      /* Safari and Chrome */
      z-index: 1051;
      width: 1px;
      height: 75px;
      margin-left: 35px;
      /* IE 9 */
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: $gray-900;
      .rl {
        /* Safari and Chrome */
        z-index: 1052;
        width: 1px;
        height: 75px;
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        background-color: $gray-900;
      }
    }
  }
}
